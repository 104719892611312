import * as api from './api'

export const ax = {
  get: api.get,
  post: api.post,
  put: api.put,
  patch: api.patch,
  delete: api.deleter
}

export default ax;
