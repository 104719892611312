import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  returnObjects: true,
  lng: "de",
  debug: window.location.host.includes("localhost"),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    de: {
      translation: {
        ...window.ITREF.config.locales.de,
      },
    },
  },
});

export default i18n;
