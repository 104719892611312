export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const UPDATE_CALENDARS = "UPDATE_CALENDARS";
export const UPDATE_CALENDAR_DETAILS = "UPDATE_CALENDAR_DETAILS";
export const REMOTE_EVENT_STATUS_CHANGE = "REMOTE_EVENT_STATUS_CHANGE";

export const updateCurrentUser = user => {
  return { type: UPDATE_CURRENT_USER, payload: user }
}

export const updateCalendars = calendars => {
  return { type: UPDATE_CALENDARS, calendars }
}

/**
 * Update title of calendar with given id in store.
 * @param {number} id
 * @param {string} title
 * @returns redux action
 */
export const updateCalendarDetails = (id, details) => {
  return { type: UPDATE_CALENDAR_DETAILS, id, details }
}

/**
 * Forwards event status update from websocket into redux store
 * @param {object} event
 * @returns action
 */
export const remoteEventStatusChange = event => {
  return { type: REMOTE_EVENT_STATUS_CHANGE, event }
}
