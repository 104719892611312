import ax from "../axios";

export const getPasswordResetData = async (userId) => {
  const response = await ax.get(`/decryption/${userId}/password_reset`);
  return response;
};

export const getPasswordChangeData = async (userId) => {
  const response = await ax.get(`/decryption/${userId}/change_password`);
  return response;
};
