import forge from "node-forge";
import {
  confirmedMember,
  declinedMember,
  updateNewMembers
} from "../actions/app";
import { updateCurrentUser } from "../actions/dashboard";
import {
  passwordResetCreateNewKeyStore
} from "../actions/decryption";
import ax from "../axios";
import { UserRole } from "../constants";
import { getCookie, getDecryptedPrivateKey, storableUser } from "../shared";
import { store } from "../store/store";
import { getPasswordChangeData } from "./decryption";

export const logIn = (params) => {
  store.dispatch(updateCurrentUser());
  return new Promise((resolve, reject) => {
    ax.post(`/auth/login`, params)
      .then((res) => {
        localStorage.setItem("token", res.data.jwt);
        const { user } = res.data;
        resolve({ ...user.data.attributes, id: user.data.id });
      })
      .catch(reject);
  });
};

/**
 * Removes locally stored tokens and redirects to base href
 */
export const logOut = (redirectTarget = "/") => {
  emptyStorage()
  window.location.href = redirectTarget;
};

export const emptyStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("obj");
}

/**
 * Returns a promise which resolves when we get user details from the backend
 * Rejects when the user is logged out
 * @returns Promise
 */
export const checkForUserInSession = async () => {
  store.dispatch(updateCurrentUser());

  if(!getCookie("inSession")) {
    localStorage.removeItem("token");
    return Promise.reject()
  }

  return new Promise((resolve, reject) => {
    currentUserSession()
      .then((res) => {
        const details = storableUser(res)
        store.dispatch(updateCurrentUser(details));
        resolve(res);
      })
      .catch(reject);
  });
};

/**
 * Deletes a User
 * @param {number} id
 * @returns
 */
export const deleteUser = (id, reason) => {
  return ax.delete(`/users/${id}?reason=${reason}`);
};

export const updatePasswordResetStatus = async (userId, status) => {
  return await ax.post(`/users/${userId}/update_password`, { status });
};

export const denyPasswordReset = async (userId) => {
  return await ax.post(`/users/${userId}/deny_password_reset`);
};

export const initializePasswordReset = async (email) => {
  return await ax.post(`/users/reset_password`, { email });
};

export const getUserData = async (email) => {
  const response = await ax.get(`/user/?email=${email}`);
  return response;
};

export const getUser = async (id) => {
  const response = await ax.get(`/users/${id}`)
  return response;
};

export const getUserFiles = async (id) => {
  const response = await ax.get(`/users/${id}/files`)
  return response
}

export const getUserUploadLink = async (id) => {
  return await ax.get(`/users/${id}/files/upload_url`)
}
export const getDownloadLink = async (id, nodeId) => {
  return await ax.get(`/users/${id}/files/download/${nodeId}`)
}
export const deleteFile = async (id, nodeId) => {
  return await ax.delete(`/users/${id}/files/${nodeId}`)
}

export const getAppliedCalendars = (id) => {
  return ax.get(`/users/${id}/applied_calendars`);
};

export const currentUserSession = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    return ax.get("/auth/auto_login");
  } else return Promise.reject();
};

export const updateRight = async (id, right) => {
  return await ax.post(`/users/${id}/rights`, { right });
};

/**
 * Retrieve a list of available TTKs
 */
export function getTtks() {
  return ax.get(`/users/ttks`);
}

export const getRequested = () => {
  return ax.get("/requested").then((res) => {
    store.dispatch(updateNewMembers(res.data.data));
  });
};

export const confirmNewMember = (id) => {
  return ax.post(`/users/${id}/confirm_registration`).then((res) => {
    if (res.request.status === 200) {
      store.dispatch(confirmedMember(id));
    }
  });
};

export const declineNewMember = (id) => {
  return updateUser({ status: "declined" }, id).then((res) => {
    store.dispatch(declinedMember(id));
  });
};

export const signUp = (userParams) => {
  return ax.post(`/users`, userParams);
};

export const updateUser = (userParams, userId) => {
  return ax.patch(`/users/${userId}`, userParams);
};

export const invite = (userParams) => {
  return ax.post(`/users`, { ...userParams, user_role: UserRole.TTK });
};

export const changedPassword = (userId) => {
  return new Promise((resolve, reject) => {
    getPasswordChangeData(userId)
      .then(({ data }) => {
        const decryptedPrivateKey = getDecryptedPrivateKey();
        const forged = forge;
        const pki = forged.pki;
        const publicKey = pki.publicKeyFromPem(data.new_public_key);

        let promises = [];
        data.key_stores_for_re_create.forEach((keyStoreData) => {
          let pro = passwordResetCreateNewKeyStore(
            keyStoreData,
            decryptedPrivateKey,
            publicKey,
            forged,
            userId
          );
          promises.push(pro);
        });
        Promise.all(promises).then(resolve).catch(reject);
      })
      .catch(reject);
  });
};
