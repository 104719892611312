import { combineReducers } from "redux";
import {
  UPDATE_CURRENT_EVENT,
  HIDE_EVENT_MODAL,
  SHOW_MODAL,
  UPDATE_SELECTED_USER,
  UPDATE_TTKS,
  UPDATE_NEW_MEMBERS,
  CONFIRMED_MEMBER,
  DECLINED_MEMBER,
  UPDATE_CURRENT_EVENT_SERIES,
  CLEAR_CURRENT_EVENT_SERIES,
  UPDATE_AUTH_CODE,
  UPDATE_PASSWORD,
  UPDATE_AUTH,
} from "../actions/app";
import { REMOTE_EVENT_STATUS_CHANGE } from "../actions/dashboard";
import { ModalID } from "../constants";
import { EventStatus } from "../eventStatus";

export function currentEvent(state = null, action) {
  switch (action.type) {
    case UPDATE_CURRENT_EVENT:
      if (action.currentEvent) {
        const { start, end } = action.currentEvent;
        if (typeof start === "string")
          action.currentEvent.start = new Date(start);
        if (typeof end === "string") action.currentEvent.end = new Date(end);
      }
      return action.currentEvent;
    case REMOTE_EVENT_STATUS_CHANGE:
      if (
        state?.id == action.event.id &&
        action.event.status !== EventStatus.BLOCKED
      ) {
        // TODO: store who's the blocking user of an event so we can
        // still show the input form in EventModal
        // https://apm.lmu-klinikum.de/issues/435
        return { ...state, status: action.event.status };
      }
      break;
  }
  return state;
}

export function currentEventSeries(state = null, action) {
  switch (action.type) {
    case UPDATE_CURRENT_EVENT_SERIES:
      if (action.series) {
        action.series.runtime_start = new Date(action.series.runtime_start);
        action.series.runtime_end = new Date(action.series.runtime_end);
        return action.series;
      }
      return null;
    case CLEAR_CURRENT_EVENT_SERIES:
      return null;
  }
  return state;
}

function showModal(state = [], action) {
  switch (action.type) {
    case HIDE_EVENT_MODAL:
      if (state.includes(ModalID.EVENT)) {
        let newState = [...state];
        newState.pop(ModalID.EVENT);
        return newState;
      }
      break;
    case SHOW_MODAL:
      const { modalId } = action;
      let newState = [...state];
      if (state.includes(modalId)) {
        newState.pop(modalId);
      } else {
        newState.push(modalId);
      }
      return newState;
  }
  return state;
}

function modalDetails(state = {}, action) {
  switch (action.type) {
    case SHOW_MODAL:
      let newState = { ...state };
      if (state[action.modalId]) {
        delete newState[action.modalId];
      } else {
        newState[action.modalId] = action.modal;
      }
      return newState;
  }
  return state;
}

export function selectedUser(state = {}, action) {
  switch (action.type) {
    case UPDATE_SELECTED_USER:
      return action.selectedUser;
  }
  return state;
}

export function ttks(state = [], action) {
  switch (action.type) {
    case UPDATE_TTKS:
      return action.ttks;
  }
  return state;
}

export function newMembers(state = [], action) {
  switch (action.type) {
    case UPDATE_NEW_MEMBERS:
      return action.members;
    case CONFIRMED_MEMBER:
    case DECLINED_MEMBER:
      return state.filter((member) => member.id !== action.id);
  }
  return state;
}

export function remoteEventUpdates(state = [], action) {
  switch (action.type) {
    case REMOTE_EVENT_STATUS_CHANGE:
      let newState = [...state];
      newState.push(action.event);
      if (newState.length > 10) newState.pop();
      return newState;
  }
  return state;
}

export function authCode(state = "", action) {
  if (action.type === UPDATE_AUTH_CODE || action.type === UPDATE_AUTH) {
    return action.authCode;
  }
  return state;
}

export function password(state = "", action) {
  if (action.type === UPDATE_PASSWORD || action.type === UPDATE_AUTH)
    return action.password;
  return state;
}

export const appStateReducer = combineReducers({
  currentEvent,
  currentEventSeries,
  showModal,
  modalDetails,
  selectedUser,
  ttks,
  newMembers,
  remoteEventUpdates,
  authCode,
  password,
});
