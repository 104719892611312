import { ModalID } from "../constants";

export const UPDATE_CURRENT_EVENT = "UPDATE_CURRENT_EVENT";
export const UPDATE_CURRENT_EVENT_SERIES = "UPDATE_CURRENT_EVENT_SERIES";
export const CLEAR_CURRENT_EVENT_SERIES = "CLEAR_CURRENT_EVENT_SERIES";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_EVENT_MODAL = "HIDE_EVENT_MODAL";
export const HIDE_CONFIRMATION_MODAL = "HIDE_CONFIRMATION_MODAL";
export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER";
export const UPDATE_TTKS = "UPDATE_TTKS";
export const UPDATE_NEW_MEMBERS = "UPDATE_NEW_MEMBERS";
export const CONFIRMED_MEMBER = "CONFIRMED_MEMBER";
export const DECLINED_MEMBER = "DECLINED_MEMBER";
export const UPDATE_AUTH = "UPDATE_AUTH";
export const UPDATE_AUTH_CODE = "UPDATE_AUTH_CODE";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

/**
 * Replace currentEvent in appState
 * @param {object} currentEvent
 */
export function updateCurrentEvent(currentEvent) {
  return { type: UPDATE_CURRENT_EVENT, currentEvent };
}
/**
 * Replace currentEventSeries in appState
 * @param {object} currentEvent
 */
export function updateCurrentEventSeries(series) {
  return { type: UPDATE_CURRENT_EVENT_SERIES, series };
}
/**
 * Clears currentEventSeries in appState
 */
export function clearCurrentEventSeries() {
  return { type: CLEAR_CURRENT_EVENT_SERIES };
}
/**
 * Show or hides modal with given ID
 * @param {String} modalId
 */
export function updateShowModal(modalId) {
  return { type: SHOW_MODAL, modalId };
}
/**
 * Hides the EventModal, if visible
 */
export function hideEventModal() {
  return { type: HIDE_EVENT_MODAL };
}

export function hideConfirmationModal() {
  return { type: SHOW_MODAL, modalId: ModalID.CONFIRMATION };
}

/**
 * Sends SHOW_MODAL action with modal parameter
 * Stored in store.appState.modalDetails.confirmationModal
 * @param {component} modal
 */
export function showConfirmationModal(modal) {
  const lsd = {
    type: SHOW_MODAL,
    modal,
    modalId: ModalID.CONFIRMATION,
  };
  return lsd;
}

export function updateSelectedUser(selectedUser) {
  return { type: UPDATE_SELECTED_USER, selectedUser };
}

export function updateTtks(ttks) {
  return { type: UPDATE_TTKS, ttks };
}

export function updateNewMembers(members) {
  return { type: UPDATE_NEW_MEMBERS, members };
}
export function confirmedMember(id) {
  return { type: CONFIRMED_MEMBER, id };
}
export function declinedMember(id) {
  return { type: DECLINED_MEMBER, id };
}

export function updateAuthCode(authCode) {
  return { type: UPDATE_AUTH_CODE, authCode };
}

export const updatePassword = (password) => {
  return { type: UPDATE_PASSWORD, password };
};

export const updateAuth = (authCode, password) => {
  return { type: UPDATE_AUTH, authCode, password };
};
