import React from 'react'
import { Alert } from "react-bootstrap";
import { SimpleApiResponse } from "../../types";

export const TrueApiResponseLayer = ({ status, message }: SimpleApiResponse) => {
  let variant = "danger"
  switch (status) {
    case 200:
    case 204:
      variant = "success"
      break;
    case 301:
    case 302:
    case 409:
      variant = "warning"
      break;
    case 400:
    case 404:
    case 500:
      variant = "danger"
      break;
  }

  return (
    <Alert variant={variant} transition={false}>
      {message}
    </Alert>
  )
}
