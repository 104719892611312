import PropTypes from "prop-types";
import { useFormik } from "formik";
import React, { useState, createElement, useEffect } from "react";
import { Button, FormGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormikErrorList } from "./FormikErrorList";
import { NotificationLayer } from "./NotificationLayer";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import { LabeledRow } from "./LabeledRow";
import { TrueApiResponseLayer } from "./TrueApiResponseLayer";

const DEFAULT_INITIAL_VALUES = { first_name: "", last_name: "", email: "" };

/**
 * Reusable Component that pipes data from "fieldsComponent" prop to
 * defined "apiEndpoint" prop and shows API response.
 * Error handling is taken care of.
 * @param {validationSchema} | Yup validation schema object
 * @param {apiEndpoint} | Function to invoke when form is sent. Needs to return a promise. Formik values are passed to this
 * @param {fieldsComponent} | Component containing the form fields
 * @param {translationScope} | Translation key containing field names, i.e. event
 * @param {initialValues} | initial values for the form
 * @returns form
 */
export const CommonForm = ({
  validationSchema,
  apiEndpoint,
  fieldsComponent,
  translationScope,
  initialValues,
  resultOnly,
  children,
  encType,
}) => {
  const [t] = useTranslation();
  const [sending, setSending] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const formik = useFormik({
    initialValues: initialValues || DEFAULT_INITIAL_VALUES,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setSending(true);
      setApiResponse(null);
      apiEndpoint(values).then(onApiResult).catch(onApiResult);
    },
  });

  const onApiResult = (response) => {
    setSending(false);

    let request;
    if (!response.hasOwnProperty("request")) {
      console.warn("Response does not have request, exiting");
      request = response;
    } else {
      request = response.request;
    }

    if (request.status == 200) {
      console.log("respoinsesennse");
      setApiResponse({
        status: 200,
        message: t(`${translationScope}.success_text`),
      });
    } else {
      const answer = {
        status: request.status,
        message: t(`${translationScope}.error_${request.status}`),
      };
      setApiResponse(answer);
    }
  };

  const getHeading = () => {
    const header1 = t(`${translationScope}.header`);
    return header1 ? <h2>{header1}</h2> : null;
  };

  if (resultOnly && apiResponse && apiResponse.status === 200) {
    return (
      <div>
        {getHeading()}
        <div>
          <p>{t(translationScope + ".success_text")}</p>
        </div>
      </div>
    );
  }

  const fields = createElement(fieldsComponent, { formik: formik });
  return (
    <Row className="common-form mb-4">
      {getHeading()}
      <div>
        <p>{t(translationScope + ".introduction_text")}</p>
      </div>

      <form
        encType={encType}
        onSubmit={formik.handleSubmit}
        className="form-group"
      >
        <FormGroup>
          {fields}
          {children}

          {Object.keys(formik.errors).length > 0 && (
            <LabeledRow>
              <FormikErrorList errors={formik.errors} />
            </LabeledRow>
          )}

          {apiResponse && (
            <TrueApiResponseLayer
              message={apiResponse.message}
              status={apiResponse.status}
            />
          )}

          {sending ? (
            <CommonSpinner />
          ) : (
            <Button
              variant="primary"
              type="submit"
              disabled={Object.keys(formik.errors).length > 0}
            >
              {t(translationScope + ".submit")}
            </Button>
          )}
        </FormGroup>
      </form>
    </Row>
  );
};
CommonForm.defaultProps = {
  resultOnly: true,
};

CommonForm.propTypes = {
  apiEndpoint: PropTypes.func.isRequired,
  fieldsComponent: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  translationScope: PropTypes.string,
  validationSchema: PropTypes.object,
  resultOnly: PropTypes.bool,
};
