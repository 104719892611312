export const EventStatus = {
  CREATED: "created",
  BLOCKED: "blocked",
  REQUESTED: "requested",
  ACCEPTED: "accepted",
  CANCELED: "canceled",
  DRAFT: "draft",
};

export const colorByStatus = {
  [EventStatus.ACCEPTED]: "green",
  [EventStatus.BLOCKED]: "grey",
  [EventStatus.REQUESTED]: "yellow",
  [EventStatus.CREATED]: "blue",
  [EventStatus.CANCELED]: "red",
  canceled_but_in_kas: "light-red",
  accepted_not_in_kas: "light-green",
};

export const iconByStatus = {
  [EventStatus.ACCEPTED]: "check",
  [EventStatus.BLOCKED]: "lock",
  [EventStatus.REQUESTED]: "clock",
  [EventStatus.CREATED]: "plus",
  [EventStatus.CANCELED]: "times",
  canceled_but_in_kas: "times",
  accepted_not_in_kas: "check",
};

export const titleByStatus = {
  [EventStatus.CREATED]: "Termin ${title}",
  [EventStatus.ACCEPTED]: "Angenommen – ${title}",
  [EventStatus.CANCELED]: "Storniert – ${title}",
  [EventStatus.BLOCKED]: "Blockiert – ${title}",
  [EventStatus.REQUESTED]: "Terminanfrage für ${title}",
};

export const statusBackgroundColor = (status) => {
  const color = colorByStatus[status] || "blue";
  return `bg-${color}`;
};

export const applyBackgroundColor = (eventInfo) => {
  const {status} = eventInfo?.event?.extendedProps;
  return statusBackgroundColor(status);
};