const API_BASE = "/api/v1";

export function sendRequest(endpoint: string, method: string, data?: any) {
  const reqParams: RequestInit = { method }
  if (data) {
    const params = new URLSearchParams()

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((value) => params.append(`${key}[]`, value.toString()))
      } else if(value) {
        params.append(key, value.toString())
      } else {
        params.append(key, '')
      }
    });

    reqParams.body = params;
  }

  return new Promise((resolve, reject) => {
    fetchAuth(API_BASE + endpoint, reqParams).then(req => {
      if(endpoint.endsWith(".html")) {
        resolve(req);
      } else {
        jsonResponse(req).then(resolve).catch(reject);
      }
    }).catch(reject)
  })
}

export function post(endpoint: string, data?: any) {
  return sendRequest(endpoint, "POST", data);
}

export function get(endpoint: string, data?: any) {
  var url = new URL( window.location.protocol + "//" + window.location.host + API_BASE + endpoint);
  for (let k in data) { url.searchParams.append(k, data[k]); }
  return new Promise((resolve, reject) => {
    fetchAuth(url).then((res) => {
      jsonResponse(res).then(resolve).catch(reject);
    }).catch(reject)
  })
}

export function deleter(endpoint: string, data?: any) {
  return sendRequest(endpoint, "DELETE", data);
}

export function put(endpoint: string, data?: any) {
  return sendRequest(endpoint, "PUT", data);
}

export function patch(endpoint: string, data?: any) {
  return sendRequest(endpoint, "PATCH", data);
}

function jsonResponse(request: any) {
  return new Promise((resolve, reject) => {
    request.json().then((json: any) => {
      resolve({ data: json, request });
    }).catch(reject)
  })
}

export function fetchAuth(url: URL|string, reqParams?: RequestInit) {
  const token = localStorage.getItem("token");
  if(token) {
    const authHeader = new Headers();
    authHeader.append('Authorization', `Bearer ${token}`);
    if(!reqParams) {
      reqParams = { headers: authHeader };
    } else {
      reqParams.headers = authHeader;
    }
  }
  return new Promise((resolve, reject) => {
    fetch(url, reqParams).then((response) => {
      if(response.status.toString().startsWith('4') || response.status.toString().startsWith('4')) {
        reject(response);
      } else {
        resolve(response)
      }
    }).catch(reject)
  })
}