export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_SUCCESSFUL = "PASSWORD_RESET_SUCCESSFUL";
export const PASSWORD_RESET_FAILED = "PASSWORD_RESET_FAILED";

import forge from "node-forge";
import { getPasswordResetData } from "../api/decryption";

import { createAppointmentKeyStore } from "../api/appointment_key_store";
import { getDecryptedPrivateKey } from "../shared";

/**
 *
 * @param {object} encryptedPrivKey
 * @param {string} authcode
 * @returns privateKey or false when fail
 */
export const simpleDecryptPrivateKey = (encryptedPrivKey, authcode) => {
  const password = getPwFromLocalStorage(authcode);
  const pki = forge.pki;
  try {
    const privateKey = pki.decryptRsaPrivateKey(
      encryptedPrivKey,
      password + authcode
    );
    return privateKey;
  } catch (e) {
    return false;
  }
};

export const slimPasswordReset = (authcode, encryptedPrivKey, userId) => {
  return new Promise((resolve, reject) => {
    getPasswordResetData(userId)
      .then((res) => {
        const decryptedPrivateKey = simpleDecryptPrivateKey(
          encryptedPrivKey,
          authcode
        );

        const data = res.data;
        const forged = forge;
        const pki = forged.pki;
        const publicKey = pki.publicKeyFromPem(data.new_public_key);
        const promises = [];

        data.key_stores_for_re_create.forEach((keyStoreData) => {
          const pro = passwordResetCreateNewKeyStore(
            keyStoreData,
            decryptedPrivateKey,
            publicKey,
            forged,
            userId
          );
          promises.push(pro);
        });

        Promise.all(promises).then(resolve).catch(reject);
      })
      .catch(reject);
  });
};

export const passwordResetCreateNewKeyStore = (
  keyStoreData,
  decryptedPrivateKey,
  publicKey,
  forged,
  userId
) => {
  const symKey = decryptedPrivateKey.decrypt(
    forged.util.decode64(keyStoreData.encrypted_sym_key)
  );
  const encryptedSymKey = forged.util.encode64(publicKey.encrypt(symKey));
  const keyStoreParams = {
    encrypted_sym_key: encryptedSymKey,
    user_id: userId,
    content: keyStoreData.content,
    appointment_id: keyStoreData.appointment_id,
  };
  return createAppointmentKeyStore(keyStoreParams);
};

/**
 * Decrypt the response for /api/v1/appointments/:id or /api/v1/events/:id/appointment
 * and return the decrpyted content object.
 * @param {object} appointment
 */
export const getAppointmentContent = (appointment, userId) => {
  const { attributes } = appointment;
  const myKeyStore = attributes.appointment_key_stores.find(
    (ks) => ks.user_id == userId
  );
  if (!myKeyStore) return console.error("No keystore found for this user");

  const myPrivateKey = getDecryptedPrivateKey();
  const decryptedSymKey = myPrivateKey.decrypt(
    forge.util.decode64(myKeyStore.encrypted_sym_key)
  );
  return decipherContent(attributes.iv, myKeyStore.content, decryptedSymKey);
};

export const decryptContent = (data, privateKey) => {
  const appointmentKeyStore = data.appointment_key_store;
  const decryptedSymKey = decryptSymKey(data, privateKey);
  return decipherContent(
    data.appointment_iv,
    appointmentKeyStore.content,
    decryptedSymKey
  );
};

export const decipherContent = (iv, content, symKey) => {
  const decipher = forge.cipher.createDecipher("AES-CBC", symKey);
  decipher.start({
    iv: forge.util.decode64(iv),
  });
  decipher.update(forge.util.createBuffer(forge.util.decode64(content)));
  decipher.finish();
  return JSON.parse(decipher.output.data);
};

export const getPwFromLocalStorage = (authCode) => {
  if (!authCode) return false;
  const obj = JSON.parse(window.localStorage.getItem("obj"));
  const salt = forge.util.decode64(obj.salt);
  const iv = forge.util.decode64(obj.iv);
  const key = forge.pkcs5.pbkdf2(authCode, salt, 4, 16);
  const decipher = forge.cipher.createDecipher("AES-CBC", key);
  decipher.start({ iv });
  const cipherText = forge.util.decode64(obj.cipher_text);
  decipher.update(forge.util.createBuffer(cipherText));
  var result = decipher.finish(); // check 'result' for true/false
  if (result) return decipher.output.data;
  return false;
};

export const decryptSymKey = (data, privateKey) => {
  const appointmentKeyStore = JSON.parse(data.appointment_key_store);
  const symKey = appointmentKeyStore.encrypted_sym_key;
  return privateKey.decrypt(forge.util.decode64(symKey));
};

export const password_reset = () => {
  return { type: PASSWORD_RESET };
};

export const password_reset_successfull = () => {
  return { type: PASSWORD_RESET_SUCCESSFUL };
};

export const password_reset_failed = () => {
  return { type: PASSWORD_RESET_FAILED };
};
